<template>
    <div>
        <ResellerTable :parent="parent"/>
    </div>
  </template>
  
  <script>
  import ResellerTable from './ResellerTable.vue'
  import appConfig from '@/app.config'
  
  export default {
      page: {
          title: 'Pending sender IDs',
          meta: [{ name: 'description', content: appConfig.description }],
      },
      name: 'pendingReseller-sender_ids',
      components: {
          ResellerTable
      },
      data() {
          return {
              parent:{
                  name:'pending',
                  storeDataName: 'pendingResellerSenderIDs',
                  from: 'pending',
                  status:0
              },
          }
      },
  }
  </script>
  
  <style>
  
  </style>